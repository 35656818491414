<template>
  <div class="pbf">
     
     <TabStatistics
       :data="data"
       />

  </div>
</template>

<script setup>

import TabStatistics from '@/pages/brands-manager/components/TabStatistics'

const props = defineProps({
  data: {
    type: Object    
  }
});

useHead(useNuxtApp().$head.getBrandManager());

/*import TabStatistics from '@/pages/brands-manager/components/TabStatistics'
  
export default { 
  props: {
    data: {
      type: Object
    }
  },
  components: {     
    TabStatistics
  },
  //head() { 
    //return this.$head.getBrandManager()
  //}
}*/
</script>

<style scoped>



/**/
 
</style>

<style>

</style>